import { Vue, Component, Inject } from 'vue-property-decorator';
import { MeasurementSeries } from '../../types';
import Api from '../../api';

let Plotly: any = require('plotly.js-dist');

@Component
export default class GuardianDataComponent extends Vue {

    @Inject() readonly api!: Api;

    chartVariable: number = 20;
    chartInterval: string = "15m";
    chartSpan: string = "1d";

    chartVariables: ChartVariable[] = [];
    chartGuardians: ChartGuardian[] = [];
    showChartLegend: boolean = true;

    mounted() {
        this.api.fetch<string[]>("api/MeasurementValue/GetVariableNames")
            .then(data => {
                this.chartVariables = data.map<ChartVariable>((name) => <ChartVariable>{ isShown: false, name: name, title: name });
            })
            .then(() => {
                for (const variable of this.chartVariables) {
                    const isShownStorage = localStorage.getItem(`chartVariable${variable.name}`);
                    if (isShownStorage) {
                        variable.isShown = isShownStorage === "true";
                    }
                }

                if (localStorage.getItem("chartSpan")) {
                    this.chartSpan = String(localStorage.getItem("chartSpan"));
                }

                if (localStorage.getItem("chartInterval")) {
                    this.chartInterval = String(localStorage.getItem("chartInterval"));
                }

                this.updateSeries();
            });
    }

    updateSeries() {

        this.chartGuardians = [];

        for (const variable of this.chartVariables) {
            Plotly.purge('chart' + variable.name);

            if (variable.isShown) {
                this.api.fetch<MeasurementSeries[]>(`api/MeasurementValue/GetValues?variableName=${variable.name}&span=${this.chartSpan}&interval=${this.chartInterval}`)
                    .then(data => this.displayChart(variable, data));
            }

            localStorage.setItem(`chartVariable${variable.name}`, variable.isShown ? "true" : "false");
        }

        localStorage.setItem("chartSpan", this.chartSpan);
        localStorage.setItem("chartInterval", this.chartInterval);
    }

    private storageChartGuardian(chartGuardian: ChartGuardian) {
        const storageName = `chartGuardian${chartGuardian.name}`;
        localStorage.setItem(storageName, chartGuardian.isShown ? "true" : "false");
    }

    setChartGuardian(chartGuardian: ChartGuardian) {
        this.storageChartGuardian(chartGuardian);
    }

    setAllChartGuardians(isShown: boolean) {
        for (let g of this.chartGuardians) {
            g.isShown = isShown;
            this.storageChartGuardian(g);
        }
    }

    private displayChart(variable: ChartVariable, series: MeasurementSeries[]) {

        // This element is only shown on larger screen sizes
        // and is used to show / hide the legend
        const showLegendTestElement = this.$refs["showLegendRef"];
        if (showLegendTestElement instanceof Element)
        {
            this.showChartLegend = showLegendTestElement.clientWidth > 0;
        }

        const data = [];

        for (const serie of series) {

            const guardianName = serie.tags.guardian
            const chartGuardian = new ChartGuardian(guardianName);

            const storageName = `chartGuardian${chartGuardian.name}`;
            const isShownGuardian = localStorage.getItem(storageName);
            if (isShownGuardian) {
                chartGuardian.isShown = isShownGuardian === "true";
            }

            this.chartGuardians.push(chartGuardian);

            if (!chartGuardian.isShown) {
                continue;
            }

            const xValues = [];
            const yValues = [];

            for (const v of serie.values) {
                xValues.push(new Date(v[0]));
                yValues.push(v[1]);
            }

            const trace = {
                type: "scatter",
                mode: "lines",
                name: guardianName,
                x: xValues,
                y: yValues,
                line: {}
            };

            data.push(trace);
        }

        const layout = {
            title: variable.title,
            showlegend: this.showChartLegend
        };

        Plotly.newPlot('chart' + variable.name, data, layout, { responsive: true, displayModeBar: false } );
    }
}

interface ChartVariable {
    isShown: boolean;
    name: string;
    title: string;
}

class ChartGuardian {
    isShown: boolean = true;
    name: string;

    constructor (name: string) {
        this.name = name;
    }
}
