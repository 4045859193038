import { Vue, Component, Inject } from 'vue-property-decorator';
import { AppSettings, CommunicatorModbusConfigModel, GuardianCoordinatorConfigModel, ModbusRegister, ToastHandler, VariableKind, VariableType } from '../../types';
import Api from '../../api';
import * as Utilities from '../../utilities';

@Component
export default class CoordinatorConfigComponent extends Vue {

    @Inject() readonly api!: Api;
    @Inject() readonly appSettings!: AppSettings;
    @Inject() readonly toastHandler!: ToastHandler;

    coordinatorConfig: GuardianCoordinatorConfigModel = {
        coordinationPeriod: "",
        variableUpdateInterval: "",
        variableUpdateTimeout: ""
    };

    private apiKey = "";

    mounted() {
        this.apiKey = this.api.getKey();
        this.reloadConfig();
    }

    update() {
        this.api
            .call("api/GuardianCoordinator/PutConfig", { method: "PUT", body: JSON.stringify(this.coordinatorConfig), headers: { "content-type": "application/json" } })
            .then(_ => {
                this.toastHandler.show("Gespeichert");
                this.reloadConfig();
            });
    }

    reload() {
        this.reloadConfig().then(_ => this.toastHandler.show("Zurückgesetzt"));
    }

    setApiKey() {
        this.api.setKey(this.apiKey);
    }

    download() {
        this.api
            .call("/api/GuardianCoordinator/GetConfigAsFile")
            .then(response => response.blob())
            .then(blob => Utilities.downloadBlob(blob,
                `ServiceConfiguration_${Utilities.formatDate(new Date())}.json`));
    }

    addModbusRegister(config: CommunicatorModbusConfigModel) {
        config.registers.push(new DummyModbusRegister());
    }


    deleteModbusRegister(config: CommunicatorModbusConfigModel, registerIdx: number) {
        if (registerIdx >= 0 && registerIdx < config.registers.length) {
            config.registers.splice(registerIdx, 1);
        }
    }

    addMailReceipient() {
        this.coordinatorConfig.mail?.mailRecipients.push("");
    }

    deleteMailReceipient(recipientIndex: number) {
        let mailRecipients = this.coordinatorConfig.mail?.mailRecipients;
        if (mailRecipients) {
            if (recipientIndex >= 0 && recipientIndex < mailRecipients.length) {
                mailRecipients.splice(recipientIndex, 1);
            }

            if (mailRecipients.length == 0) {
                mailRecipients.push("");
            }
        }
    }

    private reloadConfig(): Promise<GuardianCoordinatorConfigModel> {
        return this.api.fetch<GuardianCoordinatorConfigModel>("api/GuardianCoordinator/GetConfig")
            .then(data => this.coordinatorConfig = data);
    }
}

class DummyModbusRegister implements ModbusRegister {
    name: string = "";
    address: number = 0;
    type: string = VariableType.Single;
    kind: string = VariableKind.Energy;
}
