import './css/site.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/js/solid.min';
import '@fortawesome/fontawesome-free/js/regular.min';
import '@fortawesome/fontawesome-free/js/fontawesome.min';

import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import App from './components/app/app.vue';
import Home from './components/home/home.vue';
import GuardianManager from './components/guardian-manager/guardian-manager.vue';
import StateMachineManager from './components/state-machine-manager/state-machine-manager.vue';
import CoordinatorConfig from './components/coordinator-config/coordinator-config.vue';
import GuardianGroupConfig from './components/guardian-manager/guardian-group-config.vue';
import LogLive from './components/log-viewer/log-live.vue';
import LogViewer from './components/log-viewer/log-viewer.vue';
import GuardianData from './components/guardian-data/guardian-data.vue';
import StateMachineConfig from './components/state-machine-config/state-machine-config.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    { path: '/', component: Home },
    { path: '/guardian-manager', component: GuardianManager },
    { path: '/state-machine-manager', component: StateMachineManager },
    { path: '/coordinator-config', component: CoordinatorConfig },
    { path: '/guardian-config/:group?', component: GuardianGroupConfig, props: true },
    { path: '/log-live', component: LogLive },
    { path: '/log-viewer', component: LogViewer },
    { path: '/guardian-data', component: GuardianData },
    { path: '/state-machine-config/:stateMachineId', component: StateMachineConfig, props: true },
];

new Vue({
    router: new VueRouter({ mode: 'history', routes: routes }),
    render: h => h(App)
}).$mount('#app');
